module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:400,700","Itim:400"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"rocketchart","accessToken":"MC5ZY1dabmhJQUFDc0FaMVJ0.77-9S1Pvv70NLi4OUO-_vVhmYe-_ve-_ve-_ve-_vTXvv73vv70c77-9MBDvv73vv73vv73vv73vv70rD38","promptForAccessToken":true,"apiEndpoint":"https://rocketchart.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
