import React from 'react'
import { Link } from 'gatsby'
import { Layout, Row, Col, Typography, Divider } from 'antd'

import Logo from './logo'
import { FacebookOutlined, LinkedinOutlined } from '@ant-design/icons'

const { Footer } = Layout
const { Text, Paragraph } = Typography

const CustomFooter = () => {
  return (
    <Footer className={'rc-footer'}>
      <Row type="flex" justify="center">
        <Col xs={24} lg={6} className='rc-footer-left-links'>
          <div className="rc-footer-logo" style={{ marginRight: 50 }}>
            <Link to="/"><Logo white /></Link>
          </div>

          <div className='rc-footer-left-links-social-networks'>
            <a href="https://www.facebook.com/RocketChart.co" target="_blank" rel="noopener noreferrer" className='rc-white-text'>
              <FacebookOutlined className='rc-footer-icon mr-2' />
            </a>
            <a href="https://www.linkedin.com/company/rocketchart/" target="_blank" rel="noopener noreferrer" className='rc-white-text'>
              <LinkedinOutlined className='rc-footer-icon' />
            </a>
          </div>
        </Col>

        <Col xs={24} lg={18} className='flex flex-col md:flex-row justify-end space-x-0 md:space-x-12 items-start space-y-6 md:space-y-0'>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Fonctionnalités</Text>
            <a href="/fonctionnalites/suivi-de-tresorerie/" className="rc-white-text">Suivi de trésorerie en temps réel</a>
            <a href="/fonctionnalites/previsionnel-de-tresorerie/" className="rc-white-text">Prévisionnel de trésorerie</a>
            <a href="/fonctionnalites/scenarios-previsionnels-de-tresorerie/" className="rc-white-text">Pilotage multi-scénarios</a>
            <a href="/fonctionnalites/reduire-depenses-entreprise/" className="rc-white-text">Maîtrise des coûts</a>
            <a href="/integration-sellsy/" className="rc-white-text">Intégration Sellsy</a>
            <a href="/pricing/" className="rc-white-text">Tarifs</a>
          </div>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Ressources</Text>
            <a href="/blog/gestion-tresorerie/" className="rc-white-text">Guide Complet de la Gestion de Trésorerie</a>
            <a href="/blog/logiciel-tresorerie/" className="rc-white-text">Guide 2022 des Logiciels de Trésorerie</a>
            <a href="/alternatives/" className="rc-white-text">RocketChart by Sellsy vs. Alternatives</a>
            <a href="/blog/portraits-clients/" className="rc-white-text">Interviews clients</a>
            <a href="/help/" className="rc-white-text">Centre d{"'"}aide</a>
            <a href="/blog/" className="rc-white-text">Blog</a>
          </div>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Entreprise</Text>
            <a href="/about/" className="rc-white-text">À propos</a>
            <a href="/saas-founders-story/" className="rc-white-text">Notre histoire</a>
            <a href="/contact/" className="rc-white-text">Nous contacter</a>
            <a href="/reserver-une-demo/" className="rc-white-text">Réserver une démo</a>
            <a href="https://rocketchart-public.s3.eu-west-3.amazonaws.com/cgu.pdf" target="_blank" rel="noopener noreferrer" className="rc-white-text">Conditions générales d{"'"}utilisation</a>
            <a href="https://rocketchart-public.s3.eu-west-3.amazonaws.com/cgv.pdf" target="_blank" rel="noopener noreferrer" className="rc-white-text">Conditions générales de vente</a>
            <a href="https://rocketchart-public.s3.eu-west-3.amazonaws.com/politique-de-confidentialite.pdf" target="_blank" rel="noopener noreferrer" className="rc-white-text">Politique de confidentialité</a>
            <div dangerouslySetInnerHTML={{ __html: '<a href="javascript:Didomi.preferences.show()" class="rc-white-text">Gérer mes consentements</a>' }} />
            <a href="https://rocketchart-public.s3.eu-west-3.amazonaws.com/politique-de-cookies.pdf" target="_blank" rel="noopener noreferrer" className="rc-white-text">Politique de cookies</a>
            <a href="https://rocketchart-public.s3.eu-west-3.amazonaws.com/mentions-legales.pdf" target="_blank" rel="noopener noreferrer" className="rc-white-text">Mentions légales</a>
          </div>
        </Col>

        {/* <Col xs={{ span: 24, order: 4 }} lg={{ span: 6, order: 2 }} className='rc-footer-list-section mt-4 lg:mt-0'>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Fonctionnalités</Text>
            <Link to="/fonctionnalites/suivi-de-tresorerie" className="rc-white-text">Suivi de trésorerie en temps réel</Link>
            <Link to="/fonctionnalites/previsionnel-de-tresorerie" className="rc-white-text">Prévisionnel de trésorerie</Link>
            <Link to="/fonctionnalites/scenarios-previsionnels-de-tresorerie" className="rc-white-text">Pilotage multi-scénarios</Link>
            <Link to="/fonctionnalites/reduire-depenses-entreprise" className="rc-white-text">Maîtrise des coûts</Link>
            <Link to="/pricing" className="rc-white-text">Tarifs</Link>
          </div>
        </Col>

        <Col xs={{ span: 12, order: 3 }} lg={{ span: 6, order: 3 }} className='rc-footer-list-section'>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Ressources</Text>
            <Link to="/logiciel-de-trésorerie" className="rc-white-text">Guide 2021 des Logiciels de Trésorerie</Link>
            <Link to="/gestion-tresorerie" className="rc-white-text">Guide Complet de la Gestion de Trésorerie</Link>
            <Link to="/bilan-fonctionnel" className="rc-white-text">Guide Intégral du Bilan Fonctionnel</Link>
            <Link to="/alternatives" className="rc-white-text">Comparatif des Alternatives</Link>
          </div>
        </Col>

        <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 4 }} className='rc-footer-list-section'>
          <div className="rc-footer-link-list">
            <Text className="rc-white-text rc-bold-text">Entreprise</Text>
            <Link to="/about" className="rc-white-text">À propos</Link>
            <Link to="/saas-founders-story/" className="rc-white-text">Notre histoire</Link>
            <Link to="/contact" className="rc-white-text">Nous contacter</Link>
            <Link to="/reserver-une-demo" className="rc-white-text">Réserver une démo</Link>
            <Link to="/mentions-legales" className="rc-white-text">Mentions légales</Link>
            <Link to="/cgu" className="rc-white-text">Conditions générales</Link>
          </div>
        </Col> */}
      </Row>

      <Divider />

      <Row>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 1 }} style={{ display: 'flex', flexDirection: 'column' }}>
          <Paragraph className='rc-white-text'>RocketChart by Sellsy est un logiciel français de gestion et prévision de trésorerie. Gagnez du temps avec un suivi de trésorerie automatisé en temps réel. Réalisez simplement votre budget prévisionnel, et mesurez vos performances Réalisé vs Budget en 1 clic. Prenez les bonnes décisions grâce aux multi-scénarios prévisionnels et optimisez vos coûts avec une maîtrise précise de vos dépenses.</Paragraph>
        </Col>

        <Col xs={{ span: 24, order: 3 }} lg={{ span: 8, order: 2 }} align="center">
          <Paragraph className='rc-white-text'>© 2022 RocketChart SARL</Paragraph>
        </Col>

        <Col xs={{ span: 24, order: 2 }} lg={{ span: 8, order: 3 }} className="rc-footer-link-country">
          <Paragraph className='rc-white-text'>Imaginé et créé en France <span role="img" aria-label="fr-flag">🇫🇷</span></Paragraph>
        </Col>
      </Row>
    </Footer >
  )
}

export default CustomFooter
