import * as React from 'react'
import { graphql } from 'gatsby'

const EmptySpace = ({ slice }) => {
  return (
    <div className={`w-full h-${slice.primary.size || 2}`} />
  )
}

export const query = graphql`
  fragment BlogPostDataBodyEmptySpace on PrismicBlogPostDataBodyEmptySpace {
    primary {
      size
    }
  }

  fragment HelpdeskArticleDataBodyEmptySpace on PrismicHelpdeskArticleDataBodyEmptySpace {
    primary {
      size
    }
  }
`

export default EmptySpace
