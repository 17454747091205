import React from 'react'
import Switch from 'antd/es/switch'
// import 'antd/es/switch/style'
import cx from 'classnames'

export default function HypothesisToggle({ label, toggled, onClick }) {
  return (
    <div
      aria-checked={toggled}
      role="switch"
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
      className={cx(
        'bg-ultra-light-primary mt-2 p-4 rounded-xl font-bold text-base cursor-pointer border-2 border-transparent',
        'hover:border-primary',
        'focus:outline-none',
        { 'border-light-primary': toggled }
      )}
    >
      <Switch size='small' checked={toggled} />
      <span className='ml-2'>{label}</span>
    </div>
  )
}