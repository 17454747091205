import * as React from 'react'
import { Link } from 'prismic-reactjs'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

import { repositoryConfigs } from './src/utils/prismic-previews'

// import rmetrics from './src/services/metrics'

import './src/styles/tailwind.css'

import 'antd/es/layout/style'
import 'antd/es/divider/style'
import 'antd/es/card/style'
import 'antd/es/typography/style'
import 'antd/es/button/style'
import 'antd/es/switch/style'
import 'antd/es/row/style'
import 'antd/es/col/style'
import 'antd/es/drawer/style'
import 'antd/es/slider/style'
import 'antd/es/modal/style'
import 'antd/es/input/style'
import 'antd/es/checkbox/style'
import 'antd/es/form/style'
import 'antd/es/alert/style'
import 'antd/es/tag/style'
import 'antd/es/popover/style'

import './src/styles/shapes.css'
import './src/styles/style.less'
import './src/styles/header-menu.less'

// export const onClientEntry = async () => {
//   await rmetrics.listen()
//   await rmetrics.triggerEvent('newValue', { metricName: 'Click' })
// }

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
)
