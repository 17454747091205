import * as React from 'react'
import { graphql } from 'gatsby'

const Video = ({ slice }) => {
  return (
    <video controls className='w-full'>
      <source src={slice.primary.video_file.url} type="video/webm" />
    </video>
  )
}

export const query = graphql`
  fragment BlogPostDataBodyVideo on PrismicBlogPostDataBodyVideo {
    primary {
      video_file {
        url
      }
    }
  }

  fragment HelpdeskArticleDataBodyVideo on PrismicHelpdeskArticleDataBodyVideo {
    primary {
      video_file {
        url
      }
    }
  }
`

export default Video
