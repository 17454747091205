import React, { useCallback, useMemo, useState } from 'react'
import Col from 'antd/es/col'
import Row from 'antd/es/row'

import HypothesesContainer from './hypotheses-container'
import CashFlowChart from '../charts/CashFlowChart'
import UpDrawedArrow from '../../icons/up-drawed-arrow'

export default function HypothesisWithChart ({ jsonData, inverse, largeChart, ...rest }) {
  const { startingBalance, base, hypotheses, defaultIndexToggled } = useMemo(() => {
    const startingBalance = jsonData.startingBalance
    const base = jsonData.base
    const hypotheses = jsonData.hypotheses
    const defaultIndexToggled = hypotheses.reduce((acc, hyp, index) => { return { ...acc, [index]: false } }, {})
    return { startingBalance, base, hypotheses, defaultIndexToggled }
  }, [jsonData])

  const [indexToggled, setIndexToggled] = useState(defaultIndexToggled)

  const toggleIndex = useCallback((hypIndex) => {
    const newHypToggled = { ...indexToggled, [hypIndex]: !indexToggled[hypIndex] }
    setIndexToggled(newHypToggled)
  }, [indexToggled, setIndexToggled])

  const activatedHypotheses = useMemo(() => {
    return hypotheses.reduce((acc, hyp, index) => {
      if (indexToggled[index]) return [...acc, hyp]
      return acc
    }, [])
  }, [indexToggled, hypotheses])

  const data = useMemo(() => {
    let hypCumulatedCashflow = 0
    return base.map((baseMonth, index) => {
      const { balance, cashin, cashout } = baseMonth

      const { addedAmount, subtractedAmount, cashflow } = activatedHypotheses.reduce((acc, { results }) => {
        const { addedAmount, subtractedAmount } = results[index]
        const cashflow = addedAmount - subtractedAmount

        return {
          addedAmount: acc.addedAmount + addedAmount,
          subtractedAmount: acc.subtractedAmount + subtractedAmount,
          cashflow: acc.cashflow + cashflow
        }
      }, { addedAmount: 0, subtractedAmount: 0, cashflow: 0 })

      const result = {
        ...baseMonth,
        balance: balance + cashflow + hypCumulatedCashflow,
        cashin: cashin + addedAmount,
        cashout: cashout + subtractedAmount,
        realisedBalance: index === 0 ? balance : undefined,
        realisedCashin: index === 0 ? cashin : undefined,
        realisedCashout: index === 0 ? cashout : undefined
      }

      // To see cumulated result on balance month after month
      hypCumulatedCashflow = cashflow + hypCumulatedCashflow

      return result
    })
  }, [base, activatedHypotheses])

  const shiftedData = useMemo(() => {
    const monthsWithStart = [{ balance: startingBalance, realisedBalance: startingBalance }, ...data]
    return monthsWithStart.map((month, index) => ({ ...month, index }))
  }, [data, startingBalance])

  const orderA = useMemo(() => inverse ? 2 : 3, [inverse])
  const orderB = useMemo(() => inverse ? 3 : 2, [inverse])
  const chartSpan = useMemo(() => largeChart ? 14 : 12, [largeChart])
  const hypSpan = useMemo(() => largeChart ? 10 : 12, [largeChart])

  return (
    <Row gutter={40} type="flex" justify="center" {...rest}>
      <Col xs={{ span: 24, order: 0 }}>
        <div className='text-left mb-8 block lg:hidden'>{jsonData.purpose}</div>
      </Col>

      <Col xs={{ span: 24, order: orderA }} lg={{ span: hypSpan, order: orderB }}>
        <div className='text-left mb-8 hidden lg:block'>{jsonData.purpose}</div>

        <HypothesesContainer hypotheses={hypotheses} hypothesesToggled={indexToggled} onToggle={toggleIndex} />
      </Col>

      <Col xs={{ span: 24, order: 1 }} lg={{ span: chartSpan, order: orderA }} style={{ minHeight: 300 }}>
        <CashFlowChart data={data} shiftedData={shiftedData} />
      </Col>

      <Col xs={{ span: 24, order: 3 }} className='mt-12'>
        <Row className='text-sm flex flex-row justify-end lg:justify-center text-center'>
          <Col xs={20} lg={8} className='text-right lg:text-center'>
            <UpDrawedArrow width="60" height="60" transform="rotate(134) scale(-1,1)" className='absolute -left-16 -top-8' />
            {jsonData.message}
            <UpDrawedArrow width="60" height="60" transform="rotate(215)" className='absolute -right-16 -top-6 hidden lg:block' />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
