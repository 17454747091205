import React from 'react'
import PropTypes from 'prop-types'

const ForecastBar = ({ fill, x, y, width, height }) => {
  // const dotLineHeight = (height || 0) - 2

  return (
    <g fill={fill}>
      <rect x={x || 0} y={y || 0} width={width || 0} height={height || 0} fillOpacity='0.4' />
      <rect x={x - 6} y={y - 2} width={width + 12} height={8} stroke='white' strokeWidth={3} strokeOpacity={1} rx='4' />
    </g>
  )
}

ForecastBar.defaultProps = {
  fill: '#000000',
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

ForecastBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
}

export default ForecastBar
