import * as React from 'react'
import { graphql } from 'gatsby'

const YoutubeThumbnail = ({ slice }) => {
  return (
    <iframe loading="lazy" className='w-3/4 lg:w-1/2 m-auto mt-12' src={`https://www.youtube.com/embed/${slice.primary.youtube_video_id}?rel=0`} frameBorder="0" allowFullScreen />
  )
}

export const query = graphql`
  fragment BlogPostDataBodyYoutubeThumbnail on PrismicBlogPostDataBodyYoutubeThumbnail {
    primary {
      youtube_video_id
    }
  }

  fragment HelpdeskArticleDataBodyYoutubeThumbnail on PrismicHelpdeskArticleDataBodyYoutubeThumbnail {
    primary {
      youtube_video_id
    }
  }
`

export default YoutubeThumbnail
