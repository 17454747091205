import React from 'react'
import PropTypes from 'prop-types'
import logoBlack from '../images/logo_rocketchart_black.svg'
import logoWhite from '../images/logo_rocketchart_white.svg'

const Logo = ({ white }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {(white && (
        <img width="200px" src={logoWhite} alt="RocketChart" />
      )) || (
        <img width="200px" src={logoBlack} alt="RocketChart" />
      )}
    </div>
  )
}

Logo.propTypes = {
  white: PropTypes.bool
}

export default Logo
