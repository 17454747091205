import React, { useState } from 'react'
import { Link } from 'gatsby'
import { MenuOutlined } from '@ant-design/icons'
import { Button, Drawer, Alert } from 'antd'

import Logo from './logo'
import NavLinks from './nav-links'

const HeaderMenu = ({ noMenu }) => {
  const [drawerVisible, setDrawerVisible] = useState(false)

  return (
    <nav className="menuBar flex flex-col items-center">
      <Alert
        type="info"
        message={(
          <div>
            📌 Info : les pages du site RocketChart seront bientôt déplacées sur le site de <a href="https://go.sellsy.com/" className='ml-0.5'>Sellsy</a>.
          </div>
        )}
        banner
        showIcon={false}
        closable
        className='text-center w-full'
      />

      <div className="menuCon px-5 xl:px-0">
        <div className="-ml-1 mt-4 mr-4"><Link to="/"><Logo /></Link></div>

        {!noMenu && (
          <div className="leftMenu">
            <div className="links">
              <NavLinks mode="horizontal" />
            </div>
          </div>
        )}

        <div className="rightMenu">
          {!noMenu && (
            <a href="https://app.rocketchart.co/signin" style={{ marginRight: 20, fontSize: 14 }}>
              Se connecter
            </a>
          )}

          <a href="https://app.rocketchart.co/signup"><Button type="primary" size="large" className="rc-cta rc-header-cta">Créer un compte</Button></a>
        </div>

        {!noMenu && (
          <Button
            size='large'
            shape='circle'
            type='primary'
            onClick={() => setDrawerVisible(!drawerVisible)}
            className="mobileMenuButton"
          >
            <MenuOutlined />
          </Button>
        )}

        <Drawer
          placement="top"
          closable={false}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          className="rc-header-drawer"
        >
          <NavLinks />

          <div className='mt-8'>
            <a href="https://app.rocketchart.co/signup" className='rc-header-link'>
              Se connecter
            </a>
            <a href="https://app.rocketchart.co/signup">
              <Button type="primary" size="large" className="rc-cta rc-header-cta">Créer un compte</Button>
            </a>
          </div>
        </Drawer>
      </div>
    </nav>
  )
}

export default HeaderMenu

// return (
//   <div className="header-container">
//     <Menu mode="horizontal" style={{ lineHeight: "64px" }}>
//     </Menu>
//   </div>
// )
