import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import classNames from 'classnames'

const InfoBox = ({ slice }) => {
  const type = slice.primary.type

  return (
    <div
      className={
        classNames(
          'flex flex-row items-center my-4 relative',
          {
            'bg-light-success': type === 'success',
            'bg-yellow-200': type === 'warning',
            'bg-light-error': type === 'danger'
          }
        )
      }
    >
      <div
        className={
          classNames(
            'absolute top-0 left-0 bottom-0 w-3',
            {
              'bg-success': type === 'success',
              'bg-yellow-400': type === 'warning',
              'bg-error': type === 'danger'
            }
          )
        }
      />

      <div className='flex-grow p-4 pl-7'>
        <PrismicRichText field={slice.primary?.content?.richText} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HelpdeskArticleDataBodyInfoBox on PrismicHelpdeskArticleDataBodyInfoBox {
    slice_type
    primary {
      type
      content {
        richText
      }
    }
  }
`

export default InfoBox
