import React from 'react'
import PropTypes from 'prop-types'
import { ComposedChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine, Bar } from 'recharts'

import Colors from '../../utils/colors'
import ForecastBar from './shapes/ForecastBar'
import RealisedBar from './shapes/RealisedBar'
import CustomizedDot from './shapes/CustomizedDot'
import CustomizedLabel from './shapes/CustomizedLabel'

CashFlowChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  cashInBankEnabled: PropTypes.bool,
  cashInEnabled: PropTypes.bool,
  cashOutEnabled: PropTypes.bool,
  scenarioEnabled: PropTypes.bool,
  scenarioName: PropTypes.string
}

const margin = { top: 8, left: 8, right: 8, bottom: 8 }

export default function CashFlowChart({ data, shiftedData }) {
  return (
    <ResponsiveContainer className='rc-cashflow-chart'>
      <ComposedChart data={data} maxBarSize={30} barGap={5} barCategoryGap={15} margin={margin}>
        <XAxis axisLine={false} tickLine={false} dataKey='name' minTickGap={-20} tick={<CustomizedLabel />} />
        <XAxis xAxisId='1' dataKey='name' hide />
        <XAxis xAxisId='shiftedLine' hide dataKey='index' type='number' domain={['dataMin', 'dataMax']} />
        <YAxis hide />

        <ReferenceLine y={0} strokeDasharray='3 3' />

        <Bar
          xAxisId='1'
          dataKey='realisedCashout'
          shape={<RealisedBar radius={6} />}
          fill={Colors.cashout}
        />

        <Bar
          xAxisId='1'
          dataKey='realisedCashin'
          shape={<RealisedBar radius={6} />}
          fill={Colors.cashin}
        />

        <Bar
          dataKey='cashout'
          shape={<ForecastBar radius={6} />}
          fill={Colors.cashout}
        />

        <Bar
          dataKey='cashin'
          shape={<ForecastBar radius={6} />}
          fill={Colors.cashin}
        />

        <Line
          data={shiftedData}
          xAxisId='shiftedLine'
          dataKey='balance'
          stroke={Colors.lightPrimary}
          strokeWidth={6}
          strokeDasharray='12 6'
          dot={<CustomizedDot />}
        />

        <Line
          data={shiftedData}
          xAxisId='shiftedLine'
          dataKey='realisedBalance'
          stroke={Colors.cashflow}
          strokeWidth={6}
          dot={{ strokeWidth: 3, r: 5 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
