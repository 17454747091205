import React from "react"

const ComingTag = () => {
  return (
    <div className='py-1 px-2 rounded-full bg-gray-100 font-bold text-xs text-gray-500 w-max'>
      À venir
    </div>
  )
}

// ComingTag.propTypes = {
//   title: PropTypes.string
// }

export default ComingTag
