import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { HashtagIcon } from '@heroicons/react/solid'

const renderContent = (slice) => {
  const id = slice.primary.id
  const sectionTitle = slice.primary.section_title
  const items = slice.items

  return (
    <div key={id}>
      {!!sectionTitle && (
        <a href={`#${id}`} className='mt-12 flex flex-row items-baseline relative text-white hover:text-primary'>
          <HashtagIcon className='h-8 w-8 absolute -left-10 top-2.5 invisible lg:visible' />
          <h2 id={id} className='text-black'>{sectionTitle}</h2>
        </a>
      )}

      <PrismicRichText field={slice.primary.text.richText} />

      {items.map((item, index) => {
        return (
          <div key={index}>
            {!!item.subsection_title && (
              <a href={`#${item.subsection_id}`} className='mt-6 flex flex-row items-baseline relative text-white hover:text-primary'>
                <HashtagIcon className='h-6 w-6 absolute -left-8 top-2' />
                <h3 id={item.subsection_id} className='text-black'>{item.subsection_title}</h3>
              </a>
            )}

            <PrismicRichText field={item.subsection_text.richText} />
          </div>
        )
      })}
    </div>
  )
}

const Text = ({ slice }) => {
  const id = slice.primary.id

  if (!id) {
    return (
      <div>
        {renderContent(slice)}
      </div>
    )
  }

  return (
    <section>
      {renderContent(slice)}
    </section>
  )
}

export const query = graphql`
  fragment BlogPostDataBodyText on PrismicBlogPostDataBodyText {
    slice_type
    primary {
      id
      section_title
      text {
        richText
      }
    }
    items {
      subsection_id
      subsection_title
      subsection_text {
        richText
      }
    }
  }

  fragment HelpdeskArticleDataBodyText on PrismicHelpdeskArticleDataBodyText {
    slice_type
    primary {
      id
      section_title
      text {
        richText
      }
    }
    items {
      subsection_id
      subsection_title
      subsection_text {
        richText
      }
    }
  }
`

export default Text
