import * as React from 'react'
import { graphql } from 'gatsby'
import Button from 'antd/es/button'
import cx from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import Input from 'antd/lib/input/Input'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Form } from 'antd'
// import useForm from 'antd/lib/form/hooks/useForm'

const validateCgu = (rule, value, callback) => {
  if (value !== true) {
    callback(new Error('Vous devez cocher cette case afin de recevoir votre fichier.'))
  } else {
    callback()
  }
}

const validateMarketing = (rule, value, callback) => {
  if (value !== true) {
    callback(new Error('Vous devez cocher cette case afin de recevoir votre fichier.'))
  } else {
    callback()
  }
}

const EmailableFile = ({ slice }) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [sent, setSent] = React.useState(false)

  const hasBanner = !!slice.primary?.cta_banner
  const hasFileCover = !!slice.primary?.modal_file_cover

  const onSubmit = React.useCallback((values) => {
    setLoading(true)

    /* eslint-disable no-undef */
    _cio.identify({
      id: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      company_name: values.company_name
    })
    _cio.track('Website File Downloaded', {
      file_name: slice?.primary?.file?.raw?.name,
      file_url: slice?.primary?.file?.url,
      description_label: slice?.primary?.description_label
    })

    setTimeout(() => {
      setLoading(false)
      setSent(true)
      setVisible(false)
    }, 500)
  }, [slice, setLoading, setVisible, setSent])

  return (
    <div className={cx('relative w-full flex flex-col items-center justify-center', 'mt-12')}>
      {(hasBanner && (
        <button onClick={() => setVisible(true)}>
          <img src={slice.primary.cta_banner.url} alt='Download file banner' className='border border-solid border-gray-200 rounded-md shadow-md hover:shadow' />
        </button>
      )) || (
        <Button
          onClick={() => setVisible(true)}
          type='primary'
          size='large'
          className={cx(
            'rc-cta margin-auto'
            // {
            //   'absolute right-6 bottom-6 shadow-lg': hasBanner,
            //   'margin-auto': !hasBanner
            // }
          )}
        >
          {slice.primary?.cta_label}
        </Button>
      )}

      {sent && (
        <div className='text-success text-sm mt-4 rc-text-glow'>
          ✔ Le fichier vous a bien été envoyé par email !
        </div>
      )}

      <Modal
        width={840}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        visible={visible}
        closable={false}
        bodyStyle={{ padding: 0 }}
      >
        <div className='flex flex-col lg:flex-row items-stretch'>
          {hasFileCover && (
            <div className='flex items-center bg-gray-100 border-r border-solid border-gray-200'>
              <img
                src={slice.primary.modal_file_cover.url}
                alt='File cover'
                className='ml-auto mr-auto lg:-ml-6 -mt-6 lg:mt-0 mb-6 lg:mb-0 w-48 lg:w-96 shadow-lg hover:shadow-sm transform -rotate-2 hover:rotate-1'
              />
            </div>
          )}

          <div className='w-full p-6 pt-4'>
            <h3>{slice.primary.modal_title}</h3>

            <Form
              form={form}
              onFinish={(values) => onSubmit(values, form.resetFields)}
              layout='horizontal'
              validateTrigger={null}
              className='mt-8'
            >
              <div className='w-full flex flex-col lg:flex-row space-x-0 lg:space-x-6'>
                <Form.Item name='last_name' rules={[{ required: true, message: 'Veuillez renseigner votre nom' }]} className='w-full lg:w-1/2'>
                  <Input type='text' size='large' placeholder='Nom' autoFocus />
                </Form.Item>

                <Form.Item name='first_name' rules={[{ required: true, message: 'Veuillez renseigner votre prénom' }]} className='w-full lg:w-1/2'>
                  <Input type='text' size='large' placeholder='Prénom' />
                </Form.Item>
              </div>

              <div className='w-full flex flex-col lg:flex-row space-x-0 lg:space-x-6'>
                <Form.Item name='email' rules={[{ required: true, type: 'email', message: 'Veuillez renseigner votre email' }]} className='w-full lg:w-1/2'>
                  <Input type='email' size='large' placeholder='Email' />
                </Form.Item>

                <Form.Item name='company_name' rules={[{ required: true, message: 'Veuillez renseigner le nom de votre entreprise' }]} className='w-full lg:w-1/2'>
                  <Input type='text' size='large' placeholder='Entreprise' />
                </Form.Item>
              </div>

              <Form.Item
                name='cgu'
                rules={[{ validator: validateCgu }]}
                valuePropName='checked'
                validateTrigger='onChange'
                className='mb-0'
              >
                <Checkbox className='text-gray-400 text-justify text-xs'>En cochant cette case vous déclarez avoir lu et accepté notre <a href='https://rocketchart-public.s3.eu-west-3.amazonaws.com/politique-de-confidentialite.pdf' target='_blank' rel='noopener noreferrer'>politique de confidentialité</a>.</Checkbox>
              </Form.Item>

              <Form.Item
                name='marketing'
                rules={[{ validator: validateMarketing }]}
                valuePropName='checked'
                validateTrigger='onChange'
                className='mb-4'
              >
                <Checkbox className='text-gray-400 text-justify text-xs'>Je souhaite recevoir les communications de RocketChart.</Checkbox>
              </Form.Item>

              {/* <div className='text-xs lg:text-sm text-gray-400 mb-6'>
                En soumettant le formulaire, vous acceptez que RocketChart vous envoie des emails avec du contenu pertinent sur la gestion de trésorerie (comme précisé dans nos <a href='/cgu'>conditions générales d{"'"}utilisation</a>, vous pouvez vous désinscrire à tout moment).
              </div> */}

              <Form.Item className='w-full mb-2'>
                <Button
                  block
                  htmlType='submit'
                  type='primary'
                  size='large'
                  className='rc-cta'
                  loading={loading}
                  disabled={loading}
                >
                  {slice.primary.modal_button_label}
                </Button>
              </Form.Item>
            </Form>

            <div className='text-xs text-gray-400 px-8 lg:px-0 mt-6 text-justify'>
                Les données que vous nous fournissez sont traitées par les services clients et marketing de RocketChart aux fins de :
                <ul className='list-disc ml-4'>
                  <li>nous adresser vos demandes, accéder à nos ressources en ligne, utiliser notre Logiciel</li>
                  <li>nous permettre de vous envoyer des offres commerciales et marketing</li>
                </ul>

                Pour en savoir plus, consultez notre <a href='https://rocketchart-public.s3.eu-west-3.amazonaws.com/politique-de-confidentialite.pdf' target='_blank' rel='noopener noreferrer'>politique de confidentialité</a>.

                <br />
                Vous pouvez à tout moment demander l’accès, la rectification, la limitation, et l’effacement de vos données, ou retirer votre consentement. Pour exercer ces droits, contactez-nous à l’adresse <a href='mailto:privacy@rocketchart.co' target='_blank' rel='noopener noreferrer'>privacy@rocketchart.co</a>.
              </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const query = graphql`
  fragment BlogPostDataBodyEmailableFile on PrismicBlogPostDataBodyEmailableFile {
    primary {
      file {
        url
        raw
      }
      cta_banner {
        url
      }
      modal_button_label
      modal_file_cover {
        url
      }
      modal_title
      cta_label
      description_label
    }
  }
`

export default EmailableFile
