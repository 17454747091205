import React from 'react'
import PropTypes from 'prop-types'
import ComingTag from './coming-tag'
import cx from 'classnames'

const NavSubLink = ({ title, subtitle, to, icon, coming, className }) => {
  const Icon = icon

  return (
    <a href={to} className={cx('rc-nav-sub-link flex flex-row items-center mb-4 text-light-primary hover:text-primary', className)}>
      {icon && (
        <div className='rc-nav-sub-link-icon'>
          <Icon />
        </div>
      )}

      <div className='mx-4 flex flex-col'>
        <div className='rc-nav-sub-link-title font-bold text-black flex flex-row justify-between'>
          <div className='text-base'>{title}</div>
          {coming && (<ComingTag />)}
        </div>
        <div className='text-gray-700 rc-nav-sub-link-subtitle'>{subtitle}</div>
      </div>
    </a>
  )
}

NavSubLink.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.func,
  coming: PropTypes.bool
}

export default NavSubLink
