import * as React from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import components from '../slices'
import Layout from '../components/layout'
import Head from '../components/head'
import BlogPostHeader from '../components/blog-post-header'
import { ArrowLeftIcon } from '@heroicons/react/solid'

export const query = graphql`
  query BlogPostQuery($id: String) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      id
      uid
      url
      data {
        author_avatar {
          url
          alt
        }
        author_full_name
        author_link {
          url
        }
        cover_image {
          url
        }
        canonical {
          url
        }
        date(formatString: "LL", locale: "fr-FR")
        title
        description
        introduction {
          richText
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...BlogPostDataBodyEmptySpace
          ...BlogPostDataBodyText
          ...BlogPostDataBodyHypothesesChart
          ...BlogPostDataBodyVideo
          ...BlogPostDataBodyYoutubeThumbnail
          ...BlogPostDataBodyEmailableFile
        }
      }
    }
  }
`

const BlogPost = ({ data }) => {
  if (!data) return null

  const url = data.prismicBlogPost.url
  const blogPost = data.prismicBlogPost.data
  const title = blogPost.title
  const description = blogPost.description
  const canonical = blogPost.canonical?.url
  const date = blogPost.date
  const coverImageUrl = blogPost.cover_image?.url
  const authorAvatarUrl = blogPost.author_avatar?.url
  const authorAvatarAlt = blogPost.author_avatar?.alt
  const authorName = blogPost.author_full_name
  const authorLink = blogPost.author_link?.url

  return (
    <Layout>
      <Head
        title={title}
        description={description}
        canonical={canonical || `https://rocketchart.co${url}`}
        type='article'
        meta={[
          {
            property: 'og:image',
            content: coverImageUrl
          },
          {
            name: 'twitter:image',
            content: coverImageUrl
          }
        ]}
      >
        <script type="text/javascript" src="https://platform-api.sharethis.com/js/sharethis.js#property=5f81cd2f1aeee50012fb7c33&product=sticky-share-buttons" async="async"></script>
      </Head>

      <article className="blog-content-container space-around-row mb-24" type="flex" justify="center">
      <a href="/blog/" className='flex flex-row items-center -mt-6 mb-4'><ArrowLeftIcon className='w-4 h-4 mr-2' /> tous les articles</a>

        <img src={coverImageUrl} alt={authorAvatarAlt} className='rounded-md mb-2' />

        <h1 className='my-6'>{title}</h1>

        <div className='flex flex-row items-center mb-12'>
          <a href={authorLink} target="_blank" rel="noopener noreferrer" className="rc-blog-avatar-container">
            <img src={authorAvatarUrl} alt={authorAvatarAlt} className="rc-blog-avatar" />
          </a>

          <div className='flex flex-col ml-2'>
            <a href={authorLink} target="_blank" rel="noopener noreferrer" className='font-bold'>
              {authorName}
            </a>
            <span>{date}</span>
          </div>
        </div>

        <BlogPostHeader blogPost={blogPost} />

        <SliceZone slices={blogPost.body} components={components} />
      </article>
    </Layout>
  )
}
export default withPrismicPreview(BlogPost)
