import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import favicon from '../images/favicon.png'

function Head ({ description, lang, meta, title, canonical, type, children }) {
  const location = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || 'RocketChart est le nouveau logiciel français de gestion de trésorerie. Anticipez et pilotez votre trésorerie pour prendre les bonnes décisions.'

  // const currentPath = useMemo(() => {
  //   return location.pathname.replace(`/${intl.locale}/`, '/')
  // }, [intl.locale, location.pathname])

  const links = [
    { rel: 'icon', type: 'image/png', sizes: '64x64', href: `${favicon}` },
    { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
  ]

  if (canonical) {
    links.push({
      rel: 'canonical', href: canonical
    })
  } else {
    links.push({
      rel: 'canonical', href: location.href
    })
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || 'Logiciel de gestion de trésorerie pour TPE et PME'}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={meta.concat([
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: type || 'website'
        },
        {
          property: 'og:image',
          content: 'https://i.imgur.com/dAwwW27.png'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'msapplication-TileColor',
          content: '#603cba'
        },
        {
          name: 'msapplication-config',
          content: '/images/favicons/browserconfig.xml'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        }
      ])}
      link={links}
    >
      <script>
        {`window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "p6qpddj1",
          alignment: "right"
        };`}
      </script>

      <script>
        {`// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/p6qpddj1'
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/p6qpddj1';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </script>

      {/* <!-- Google Tag Manager --> */}
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NZHTJWS');`}</script>
      {/* <!-- End Google Tag Manager --> */}
      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-82178374-4"></script> */}
      {/* <script>{'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'UA-82178374-4\');'}</script> */}
      {children}
    </Helmet>
  )
}

Head.defaultProps = {
  lang: 'fr',
  meta: [],
  description: '',
  canonical: null,
  type: null
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Head
