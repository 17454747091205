import React from 'react'
import HypothesisToggle from './hypothesis-toggle'

export default function HypothesisContainer({ label, hypotheses, hypothesesToggled, onToggle }) {
  return (
    <div>
      {
        hypotheses.map(({ name }, index) => (
          <HypothesisToggle
            key={index}
            label={name}
            toggled={hypothesesToggled[index]}
            onClick={() => onToggle(index)}
          />
        ))
      }
    </div>
  )
}