import React from 'react'
import Colors from '../../../utils/colors'

/* eslint-disable jsx-a11y/accessible-emoji */
export default function CustomizedDot(props) {
  const { cx, cy, value } = props

  if (value < 0) {
    return (
      <svg x={cx - 16} y={cy - 16} width={32} height={32}>
        <rect x="3" y="3" width={26} height={26} stroke={Colors.lightPrimary} strokeWidth="3px" fill="white" rx={13} />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize='12'>⚠️</text>
      </svg>
    )
  }

  return (
    <svg x={cx - 7} y={cy - 7} width={14} height={14}>
      <rect x="2" y="2" width={10} height={10} stroke={Colors.lightPrimary} strokeWidth="3px" fill="white" rx={5} />
    </svg>
  )
}