import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import HypothesesWithChart from '../components/hypotheses/hypotheses-with-chart'
import fetch from 'node-fetch'

const HypothesesChart = ({ slice }) => {
  const largeChart = slice?.primary?.large_chart
  const inverse = slice?.primary?.inverse_chart_and_hypotheses_order

  const [data, setData] = useState({})
  useEffect(() => {
    fetch(slice?.primary?.json_data?.url)
      .then(response => response.json())
      .then(resultData => { setData(resultData) })
  }, [slice, setData])

  if (Object.keys(data).length === 0) return null

  return (
    <HypothesesWithChart jsonData={data} largeChart={largeChart} inverse={inverse} />
  )
}

export const query = graphql`
  fragment BlogPostDataBodyHypothesesChart on PrismicBlogPostDataBodyHypothesesChart {
    primary {
      large_chart
      inverse_chart_and_hypotheses_order
      json_data {
        url
      }
    }
  }
`

export default HypothesesChart
