import EmptySpace from './empty-space'
import Text from './text'
import HypothesesChart from './hypotheses-chart'
import Video from './video'
import YoutubeThumbnail from './youtube-thumbnail'
import EmailableFile from './emailable-file'
import InfoBox from './info-box'

export default {
  empty_space: EmptySpace,
  text: Text,
  hypotheses_chart: HypothesesChart,
  video: Video,
  youtube_thumbnail: YoutubeThumbnail,
  emailable_file: EmailableFile,
  info_box: InfoBox
}
