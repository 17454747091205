// const primary = '#6179F9'
// const success = '#4DAD71'
// const error = '#F74141'
// const warning = '#E5BB8B'

const primary = '#6179F9'
const success = '#4DAD71'
const error = '#F74141'
const warning = '#E5BB8B'

const colors = {
  primary,
  lightPrimary: '#B4C4FA',
  success,
  error,
  warning,

  // aliases
  cashflow: primary,
  cashin: success,
  cashout: error
}

export default colors
