import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"

import HeaderMenu from "./header-menu"
import Footer from "./footer"

const { Content } = Layout

const CustomLayout = ({ children, noHeader, noFooter, background }) => {
  return (
    <Layout style={{ background: background || "white" }}>
      <HeaderMenu noMenu={noHeader} />

      <Content className="layout-container" style={{ background: background || "white" }}>
        {children}
      </Content>

      {!noFooter && (
        <Footer />
      )}
    </Layout>
  )
}

CustomLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  background: PropTypes.string
}

export default CustomLayout
