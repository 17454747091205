/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
// from https://github.com/prismicio/gatsby-blog/blob/master/src/utils/prismicPreviews.js

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './prismic-link-resolver'

import BlogPost from '../pages/{PrismicBlogPost.url}'
// import BlogIndex from '../pages/{PrismicBlogIndex.url}'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_post: BlogPost
      // blog_index: BlogIndex
    })
  }
]
