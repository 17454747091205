import React from 'react'
import PropTypes from 'prop-types'

const RealisedBar = ({ fill, x, y, width, height }) => {
  return (
    <g fill={fill}>
      <rect x={x || 0} y={y || 0} width={width || 0} height={height || 0} />
    </g>
  )
}

RealisedBar.defaultProps = {
  fill: '#000000',
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

RealisedBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
}

export default RealisedBar