import React from 'react'

import NavSubLink from './nav-sub-link'
import { DownOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

// import SuiviTresorerieIcon from '../icons/suivi-tresorerie'
// import PrevisionnelTresorerieIcon from '../icons/previsionnel-tresorerie'
// import PilotageMultiScenarioIcon from '../icons/pilotage-multi-scenario'
// import MaitriseDesCoutsIcon from '../icons/maitrise-des-couts'
// import IntegrationIcon from '../icons/integrations'

// const features = (
//   <div className='flex flex-col max-w-md'>
//     <NavSubLink
//       title='Suivi de trésorerie en temps réel'
//       subtitle='Gagnez du temps et réduisez les erreurs en automatisant votre reporting'
//       to='/fonctionnalites/suivi-de-tresorerie'
//       icon={SuiviTresorerieIcon}
//     />

//     <NavSubLink
//       title='Prévisionnel de trésorerie'
//       subtitle='Pilotez sereinement votre entreprise avec des prévisions fiables'
//       to='/fonctionnalites/previsionnel-de-tresorerie'
//       icon={PrevisionnelTresorerieIcon}
//     />

//     <NavSubLink
//       title='Pilotage multi-scénarios'
//       subtitle='Prenez de meilleures décisions en simulant différentes hypothèses business'
//       to='/fonctionnalites/scenarios-previsionnels-de-tresorerie'
//       icon={PilotageMultiScenarioIcon}
//     />

//     <NavSubLink
//       title='Maîtrise des coûts'
//       subtitle='Réduisez vos dépenses en analysant vos différents postes de charge'
//       to='/fonctionnalites/reduire-depenses-entreprise'
//       icon={MaitriseDesCoutsIcon}
//     />

//     <NavSubLink
//       title='Intégrations'
//       subtitle='Libérez tout le potentiel en synchronisant vos logiciels de facturation, comptables et ERP'
//       to='/fonctionnalites/integrations-logiciels-comptable-facturation-erp'
//       icon={IntegrationIcon}
//     />
//   </div>
// )

// const pourQui = (
//   <div className='flex flex-col max-w-md'>
//     <NavSubLink
//       title='Pour les Startups'
//       subtitle='Automatisez vos reporting, maîtrisez votre cash burn et contrôlez votre trésorerie'
//       to='/solutions/startups'
//     />

//     <NavSubLink
//       title='Pour les TPE'
//       subtitle='Gérez et anticipez facilement votre trésorerie pour rester serein toute l’année'
//       to='/solutions/tpe'
//     />

//     <NavSubLink
//       title='Pour les PME'
//       subtitle='Gagnez en visibilité, automatisez votre suivi et maîtrisez votre trésorerie prévisionnelle'
//       to='/solutions/pme'
//     />

//     <NavSubLink
//       title='Pour les Groupes'
//       subtitle='Pilotez facilement la trésorerie consolidée du groupe et celle de vos filiales'
//       to='/solutions/groupes-et-filiales'
//       coming
//     />

//     <NavSubLink
//       title='Pour les Experts-Comptables'
//       subtitle='Proposez un nouveau service à vos clients, démarquez-vous et développez votre cabinet'
//       to='/solutions/experts-comptables'
//     />
//   </div>
// )

const ressources = (
  <div className='flex flex-col max-w-md'>
    <NavSubLink
      title='Accéder au Blog'
      subtitle='Découvrez nos ressources pour gérer efficacement votre trésorerie.'
      to='/blog/'
      className='mt-2'
    />

    <NavSubLink
      title='Interviews d’Experts'
      subtitle='Montez en compétence dans la gestion de votre trésorerie.'
      to='/blog/interviews-daf/'
    />

    <a href='/help/' className='rc-nav-sub-link flex flex-row items-center mb-4 text-light-primary hover:text-primary'>
      <div className='mx-4 flex flex-col'>
        <div className='rc-nav-sub-link-title font-bold text-black flex flex-row justify-between'>
          <div className='text-base'>Support & Guide d’utilisation</div>
        </div>
        <div className='text-gray-700 rc-nav-sub-link-subtitle'>Utilisez toute la puissance de notre outil de gestion de trésorerie.</div>
      </div>
    </a>
  </div>
)

const NavLinks = () => {
  return (
    <>
      {// <Popover placement="bottom" content={features} trigger="hover" overlayClassName='rc-header-popover'>
        // <span className='rc-header-link'>Fonctionnalités <DownOutlined style={{ fontSize: 12 }} /></span>
        // </Popover>
        // <Popover placement="bottom" content={pourQui} trigger="hover" overlayClassName='rc-header-popover'>
        // <span className='rc-header-link'>Pour qui ? <DownOutlined style={{ fontSize: 12 }} /></span>
        // </Popover>
      }
      <a href="/#fonctionnalites" className='rc-header-link'>Fonctionnalités</a>
      <a href="/pricing/" className='rc-header-link'>Tarifs</a>
      <a href="/blog/portraits-clients/" className='rc-header-link'>Clients</a>
      <Popover placement="bottom" content={ressources} trigger="hover" overlayClassName='rc-header-popover'>
        <span className='rc-header-link'>Ressources <DownOutlined style={{ fontSize: 12 }} /></span>
      </Popover>
      <a href="/about/" className='rc-header-link'>À propos</a>
    </>
  )
}

export default NavLinks
