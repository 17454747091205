/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'blog_post': {
      return `/blog/${doc.uid}/`
    }

    case 'blog_category': {
      return `/blog/${doc.uid}/`
    }

    // case 'helpdesk_category': {
    //   return `/help/${doc.uid}/`
    // }

    // case 'helpdesk_article': {
    //   return `/help/${doc.data.category.uid}/${doc.uid}/`
    // }

    default:
      return '/'
  }
}
