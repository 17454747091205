import { ReplyIcon } from '@heroicons/react/solid'
import { PrismicRichText } from '@prismicio/react'
import * as React from 'react'

const BlogPostHeader = ({ blogPost }) => {
  const slices = blogPost.body

  return (
    <header className='mb-12'>
      {!!blogPost.introduction && (
        <PrismicRichText field={blogPost.introduction.richText} />
      )}

      <div className='font-bold mt-4'>Sommaire</div>

      <nav className="space-y-1 mt-2">
        {slices.map((slice, index) => {
          if (slice.slice_type !== 'text' || !slice.primary?.id || !slice.primary?.section_title) return null

          return (
            <div key={`section-${index}`}>
              <a
                href={`#${slice.primary?.id}`}
                className='text-primary hover:bg-ultra-light-primary hover:bg-opacity-40 hover:text-dark-primary flex items-center px-3 py-2 text-sm font-medium rounded-md'
              >
                {slice.primary.section_title}
              </a>

              {slice.items.map((item, index) => {
                if (!item.subsection_title || !item.subsection_id) return null

                return (
                  <div key={`subsection-${index}`} className='flex flex-row items-baseline'>
                    <ReplyIcon className='text-gray-400 h-4 w-4 min-w-max transform rotate-180 ml-3 mr-1' />

                    <a
                      href={`#${item.subsection_id}`}
                      className='flex-grow text-primary hover:bg-ultra-light-primary hover:bg-opacity-40 hover:text-dark-primary px-2 lg:px-3 py-2 text-sm font-medium rounded-md'
                    >
                      {item.subsection_title}
                    </a>
                  </div>
                )
              })}
            </div>
          )
        })}
      </nav>

    </header>
  )
}

export default BlogPostHeader
